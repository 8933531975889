/**
 * Root element of the current document.
 *
 * @type {HTMLHtmlElement}
 */
const root = document.documentElement;

/**
 * Test for a minimum set of JavaScript features required to run the fancy bits
 * of the theme.
 *
 * @return {boolean}
 */
const isLegacyBrowser = () => (
  !('addEventListener' in window) ||
  !('classList' in root) ||
  !('querySelector' in document) ||
  !('localStorage' in window)
);

/**
 * Test for whether the current client supports `object-fit`. Loads a polyfill
 * if required.
 *
 * @return {Promise}
 */
const fillObjectFit = () => (
  new Promise((resolve, reject) => (
    'objectFit' in document.documentElement.style
      ? resolve()
      : import(/* webpackChunkName: 'polyfills.objectfit' */ 'object-fit-images')
        .then((module) => {
          const { default: objectFitImages } = module;
          objectFitImages();
          return module;
        })
        .then(resolve)
        .catch(reject)
  ))
);

/**
 * Test for IntersectionObserver support. Loads a polyfill if required
 * (IE11, Edge 15, Safari).
 *
 * @return {Promise}
 */
const fillIntersectionObserver = () => (
  new Promise((resolve, reject) => (
    'IntersectionObserver' in window
      ? resolve()
      : import(/* webpackChunkName: 'polyfills.observer' */ 'intersection-observer')
        .then(resolve)
        .catch(reject)
  ))
);

/**
 * Load required polyfills and run the application.
 *
 * @return {void}
 */
const main = () => {
  // Cutting the mustard.
  if (isLegacyBrowser()) {
    return;
  }

  // Add a hook that can be used within the stylesheet.
  root.classList.remove('no-js');
  root.classList.add('js');

  // Add missing polyfills and run application.
  Promise.all([
    fillObjectFit(),
    fillIntersectionObserver()
  ])
    .then(() => (
      import(/* webpackChunkName: 'app', webpackPreload: true */ './app')
        .then((module) => module.default)
        .then((app) => app.init())
    ));
};

main();
